import React from 'react'
import cn from 'classnames'
import Container from '../../container/Container'
import style from './Intro.module.scss'

export default function Intro(): JSX.Element {
    return (
        <Container className={style.intro}>
            <div className={style.intro__title}>
                <h2 className="h h--1">Nye veje  <br/>til vækst</h2>
                <p className={cn('text', style.intro__title__welcome)}>Velkommen hos NoA</p>
            </div>
            <p className="text text--large text--left">Vækst er et logisk ord. At ønske sig vækst er et logisk valg. Men vejen til vækst er ikke logisk. Vi ved, at mennesker træffer 95% af deres beslutninger på mavefornemmelse. Vi forstår, hvordan mavefornemmelsen skal stimuleres, før de handler og vi forstår hvordan man bevæger sig til bunden af din salgsfunnel, hvor kunden nikker ja.</p>
            <p className="text text--large text--right">I NoA-huset i København er vi 200 specialister, som bruger vores vidtforgrenede evner inden for strategi, positionering, kommunikation, sustainability rådgivning, design og data til at forene vores kunder med deres kunder. Vi tror på samarbejde. På <a href="https://www.thenorthalliance.com/green-shift/">bæredygtighed</a>. På research. På afsæt i data. Og derfra hopper vi. Ud hvor følelserne hersker. Vi kan sagtens forklare dig hvordan. Men vi vil hellere vise dig det.</p>
        </Container>
    )
}