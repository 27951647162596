
import './assets/style/app.scss'
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'

import { Router, Location} from '@reach/router'
import { Helmet } from 'react-helmet'
import {  motion } from 'framer-motion'
import FontFaceObserver from 'fontfaceobserver'
import TagManager from 'react-gtm-module'
import Jobs from './pages/jobs'
import Header from './components/header/Header'
import Home from './pages/home'
import PrivacyPolicy from './pages/privacy-policy'

TagManager.initialize({
    gtmId: 'GTM-KQ5J265'
})

const FramerRouter = ({ children, fontsReady }: { children: any, fontsReady: boolean }) => (
    <Location>
        {({ location }) => (
            <>
                <Header fontsReady={fontsReady} />
                <motion.main
                    initial={{ opacity: 0 }}
                    animate={{
                        opacity: fontsReady ? 1 : 0,
                    }}
                    transition={{
                        delay: 0.85,
                    }}
                >
                    <Router key={location.key} location={location} primary={false}>
                        {children}
                    </Router>
                </motion.main>
            </>
        )}
    </Location>
)



const App = () => {

    const [fontsReady, setFontsReady] = useState(false)

    useEffect(() => {
        const observer = new FontFaceObserver('Aften Screen', {
            weight: 400,
            style: 'normal'
        })

        observer.load()
            .then(() => {
                setFontsReady(true)
            })
            .catch(() => {
                setFontsReady(true)
            })
    }, [])


    return (
        <>
            <Helmet title="Home" />
            <FramerRouter fontsReady={fontsReady}>
                <Home {...{path: '/'}}  />
                <Jobs {...{path: '/jobs'}}  />
                <PrivacyPolicy {...{path: '/privacy-policy'}}  />
            </FramerRouter>
        </>
    )
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
/* serviceWorker.unregister(); */
