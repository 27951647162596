import React, { useEffect, useRef } from 'react'
import cn from 'classnames'
import Container from '../../container/Container'
import style from './Jobs.module.scss'
import andco from '../../../assets/logos/andco.svg'
import bold from '../../../assets/logos/bold.svg'
import noaignite from '../../../assets/logos/noaignite.svg'
import noaconnect from '../../../assets/logos/noaconnect.svg'
import noahealth from '../../../assets/logos/noahealth.svg'
import noaconsulting from '../../../assets/logos/noaconsulting.svg'
import { useResponsiveVideo } from '../../../utils'
import { Button } from '../../molecule/Button'



export default function Jobs(): JSX.Element {
    const videoRef = useRef<HTMLVideoElement>(null)
    const wrapperRef = useRef<HTMLDivElement>(null)
    const [videoSrc] = useResponsiveVideo({
        1280: 'https://player.vimeo.com/external/544960782.hd.mp4?s=f57a920eecd7743ed5599c7e6ffad0cdc53c67d1&profile_id=174',
        960: 'https://player.vimeo.com/external/544960782.sd.mp4?s=725a98968cbcef2d4006c11762bfcbf76e175e16&profile_id=165',
        640: 'https://player.vimeo.com/external/544960782.sd.mp4?s=725a98968cbcef2d4006c11762bfcbf76e175e16&profile_id=164'
    })

    useEffect(() => {
        const observer = new IntersectionObserver(async ([entry]) => {
            if (entry.isIntersecting) {
                try {
                    await videoRef.current.play()
                } catch (e) {
                    // do nothing
                }
            } else {
                videoRef.current.pause()
            }
        }, { threshold: 0, rootMargin: '150px 0px 150px 0px' })

        observer.observe(wrapperRef.current)

        return () => {
            observer.disconnect()
        }
    }, [])

    return (
        <div ref={wrapperRef} className={style.jobs}>
            <Container>
                <h2 className="visually-hidden">Jobs</h2>
                <h2 className={`${style.jobs__title} h h--2`}>Find dit næste job hos NoA</h2>
                <p className={`${style.jobs__subtitle} text text--medium`}>Vi leder efter flere, der ikke er ligesom os. Det er sådan, vi bedst hjælper vores kunder med alt fra menneskelige indsigter til datadrevet marketing, som vi bl.a. omsætter til grøn strategi, forretningsinnovation, unikke designløsninger og emotionelle fortællinger. Og der er vores forskelligheder vores styrke. Hvis du vil være med til at tegne fremtiden for nogle af Nordens største virksomheder, skal du blive en del af os.</p>

                <ul className={style.jobs__list}>
                    <li className={style.jobs__list__item}>
                        <img className={style.jobs__list__item__image} alt="&amp;CO" src={andco} />
                        <h3 className="visually-hidden">&Co.</h3>
                        <p className={cn(style.jobs__list__item__text, 'text')}>Baseret på data og indsigt forener vi virksomheder og deres kunder gennem stærke, emotionelle fortællinger. Vi tror på, at væksten bor i menneskets følelsesverden inde bag det umiddelbart logiske.</p>
                        <a className={style.jobs__list__item__link} target="_blank" rel="noreferrer noopener" href="https://www.andco.dk/">Besøg &Co.</a>
                        <div className={style.jobs__list__item__buttons} >
                            <Button className={style.jobs__list__item__buttons__link} href="https://careers.andco.dk/connect">Connect</Button>
                            <Button className={style.jobs__list__item__buttons__link} href="https://careers.andco.dk/jobs">Ledige jobs</Button>                   
                        </div>
                    </li>
                    <li className={style.jobs__list__item}>
                        <img className={style.jobs__list__item__image} alt="Bold Scandinavia" src={bold} />
                        <h3 className="visually-hidden">Bold Scandinavia</h3>
                        <p className={cn(style.jobs__list__item__text, 'text')}>Vi skaber oplevelser og visuelle identiteter til organisationer og brands, der ved, at et stærkt brand øger kundernes villighed til at betale premium. Brands i dag skal opleves, så vi arbejder med brandoplevelser holistisk.</p>
                        <a className={style.jobs__list__item__link} target="_blank" rel="noreferrer noopener" href="https://www.boldcph.com/">Besøg Bold Scandinavia</a>
                        <div className={style.jobs__list__item__buttons} >
                            <Button className={style.jobs__list__item__buttons__link} href="https://careers.boldscandinavia.com/connect">Connect</Button>
                            <Button className={style.jobs__list__item__buttons__link} href="https://careers.boldscandinavia.com/jobs/?location=K%C3%B8benhavn">Ledige jobs</Button>                   
                        </div>
                    </li>
                    
                    <li className={style.jobs__list__item}>
                        <img className={cn(style.jobs__list__item__image, style['jobs__list__item__image--taller'])} alt="Noa Ignite" src={noaignite} />
                        <h3 className="visually-hidden">Noa Ignite</h3>
                        <p className={cn(style.jobs__list__item__text, 'text')}>Vi bruger data, teknologi og menneskelig indsigt til at omsætte virksomheders formål, strategi og vision til digitale løsninger, som gør en forskel i deres kunders liv. Værdien bor i deres kærlighed.</p>
                        <a className={style.jobs__list__item__link} target="_blank" rel="noreferrer noopener" href="https://noaignite.com/">Besøg NoA Ignite</a>
                        <div className={style.jobs__list__item__buttons} >
                            <Button className={style.jobs__list__item__buttons__link} href="https://careers-dk.noaignite.com/connect">Connect</Button>
                            <Button className={style.jobs__list__item__buttons__link} href="https://careers-dk.noaignite.com/jobs">Ledige jobs</Button>                  
                        </div>
                    </li>
                    <li className={style.jobs__list__item}>
                        <img className={style.jobs__list__item__image} alt="Noa Ignite" src={noahealth} />
                        <h3 className="visually-hidden">NoA Health</h3>
                        <p className={cn(style.jobs__list__item__text, 'text')}>I en stramt reguleret branche skaber vi den kreative kontekst, der sikrer, at sundhedspersonalet har viden og indsigt til at kunne forbinde mennesker med den medicin, der vil hjælpe dem bedst.</p>
                        <a className={style.jobs__list__item__link} target="_blank" rel="noreferrer noopener" href="https://noahealth.com/">Besøg NoA Health</a>
                        <div className={style.jobs__list__item__buttons} >
                            <Button className={style.jobs__list__item__buttons__link} href="https://noahealth.teamtailor.com/connect">Connect</Button>
                            <Button className={style.jobs__list__item__buttons__link} href="https://noahealth.teamtailor.com/jobs">Ledige jobs</Button>                     
                        </div>
                    </li>
                    <li className={style.jobs__list__item}>
                        <img className={style.jobs__list__item__image} alt="Noa Connect" src={noaconnect} />
                        <h3 className="visually-hidden">NoA Connect</h3>
                        <p className={cn(style.jobs__list__item__text, 'text')}>Vi hjælper marketing med at forbinde processer, personer, platforme og partnere. Ved at søge og forstå data bedre, kan marketing designe mere effektive funnels baseret på dyb kundeindsigt.</p>
                        <a className={style.jobs__list__item__link} target="_blank" rel="noreferrer noopener" href="https://noaconnect.com/">Besøg NoA Connect</a>
                        <div className={style.jobs__list__item__buttons} >
                            <Button className={style.jobs__list__item__buttons__link} href="https://careers-dk.noaconnect.com/connect">Connect</Button>
                            <Button className={style.jobs__list__item__buttons__link} href="https://careers-dk.noaconnect.com/jobs">Ledige jobs</Button>                     
                        </div>
                    </li>
                    <li className={style.jobs__list__item}>
                        <img className={cn(style.jobs__list__item__image, style['jobs__list__item__image--taller'])} alt="Noa Consulting" src={noaconsulting} />
                        <h3 className="visually-hidden">NoA Consulting</h3>
                        <p className={cn(style.jobs__list__item__text, 'text')}>Vi bevæger vi os i krydsfeltet imellem analyse, brandstrategi og kreativitet og omdanner markedsmuligheder til kommercielle resultater igennem positionering og innovation.</p>
                        <a className={style.jobs__list__item__link} target="_blank" rel="noreferrer noopener" href="https://noaconsulting.com/">Besøg NoA Consulting</a>
                        <div className={style.jobs__list__item__buttons} >
                            <Button className={style.jobs__list__item__buttons__link} href="https://careers.noaconsulting.com/connect">Connect</Button>
                            <Button className={style.jobs__list__item__buttons__link} href="https://careers.noaconsulting.com/jobs">Ledige jobs</Button>                
                        </div>
                    </li>
                </ul>

                <video
                    src={videoSrc}
                    ref={videoRef}
                    className={style.jobs__video}
                    playsInline
                    muted
                    preload="none"
                    poster="/static/images/droneposter.jpg"
                    loop
                />
            </Container>
        </div>
    )
}
