import React from 'react'
import { Link } from '@reach/router'
import cn from 'classnames'
import Container from '../../container/Container'
import style from './ContactUs.module.scss'
import noa from '../../../assets/logos/noa.svg'

export default function ContactUs(): JSX.Element {

    const handleClick = () => {
        try {
            window.scrollTo(0, 0)
        } catch (error) {
            // Silent error
        } 
    }

    return (
        <>
            <div className={style['contact-us']} id="kontakt">
                <Container>
                    <div className={style['contact-us__title']}>
                        <h2 className="h h--1">Lad os <br />  tage en <br />  snak</h2>

                        <div className={style['contact-us__text']}>
                            <p className={cn('text text--fluffy  text--medium')}>
                                Mikael Jørgensen <br />
                                <span lang="en">Group CEO</span>  <br />
                                <a href="mailto:mikael@thenorthalliance.com">mikael@thenorthalliance.com</a>  <br />
                                <a href="tel:+45 2030 6613">+45 2030 6613</a>
                            </p>
                        </div>

                    </div>

                    <p className={cn(style['contact-us__noa'], 'text text--medium')} lang="en">
                        A part of the <a href="https://www.thenorthalliance.com/" rel="noreferrer noopener" target="_blank">NoA Family</a>.
                    </p>

                    

                    <img className={style['contact-us__noa-logo']} src={noa} alt="NoA" />
                    <p className={style['contact-us__noa-tagline']}>New paths to growth</p>
                    
                </Container>
            </div>

            <div className={style['privacy-policy']} id="privacy-policy">
                <Container>
                    <Link to="/privacy-policy" onClick={ () => handleClick() }>Privacy Policy</Link>
                </Container>
            </div>
        </>
    )
}