import React, { useEffect, useRef, useState } from 'react'
import isTouchDevice from 'is-touch-device'
import cn from 'classnames'
import Container from '../../container/Container'
import style from './Clients.module.scss'
import tv2 from '../../../assets/logos/tv2.svg'
import codan from '../../../assets/logos/codan.svg'
import maersk from '../../../assets/logos/maersk.svg'
import sas from '../../../assets/logos/sas.svg'
import velux from '../../../assets/logos/velux.svg'
import telenor from '../../../assets/logos/telenor.svg'
import danskebank from '../../../assets/logos/danskebank.svg'
import momondo from '../../../assets/logos/momondo.png'
import cbb from '../../../assets/logos/cbb.png'
import raadet from '../../../assets/logos/raadet.png'
import tivoli from '../../../assets/logos/tivoli.png'
import lakridsbybulow from '../../../assets/logos/lakridsbybulow.png'
import novonordisk from '../../../assets/logos/novonordisk.png'

interface CardProps {
    active: number
    index: number
    total: number
    name: string
    logo: string
    hasLargeLogo?: boolean
    text: string
    dragX: number
    isDragging: boolean
    setActive: () => void
}

function Card({
    name,
    logo,
    hasLargeLogo, 
    text,
    active,
    index,
    isDragging,
    dragX,
    total,
    setActive
}: CardProps) {
    const ref = useRef<HTMLLIElement>(null)
    const [width, setWidth] = useState(0)
    const [size, setSize] = useState([window.innerWidth])
    const x = dragX + size[0] / 2 + width * index - (total / 2) * width - width / 2 + (total / 2 - active) * width
    let rotation = 0

    if (index > active) {
        rotation = (active - index) * -2
    } else if (index < active) {
        rotation = (index - active) * 2
    }

    useEffect(() => {
        if (ref.current) {
            let tid
            const onResize = () => {
                clearTimeout(tid)
                tid = setTimeout(() => {
                    const { width } = ref.current.getBoundingClientRect()
                    setWidth(width)
                    setSize([window.innerWidth])
                }, 150)
            }

            onResize()

            window.addEventListener('resize', onResize)

            return () => {
                window.removeEventListener('resize', onResize)
            }
        }
    }, [])

    return (
        <li
            ref={ref}
            className={style.card}
            style={{
                transform: ` 
                    translate3d(${x.toFixed(1)}px, ${Math.abs(rotation ** 2) * 5}px, 0px)   
                `,
                transition: isDragging ? 'none' : undefined
            }}
        >
            <div
                className={style.card__inner}
                onClick={() => {
                    setActive()
                }}
                onKeyDown={({ key }) => {
                    if (key === ' ' || key === 'Enter') {
                        setActive()
                    }
                }}
                tabIndex={isTouchDevice() ? -1 : 0}
                role="button"
                onFocus={setActive}
                style={{
                    transform: `rotate(${(rotation)}deg)`,
                    transition: isDragging ? 'none' : undefined
                }}
            >
                <h3 className="visually-hidden">{name}</h3>
                <img alt={name} className={cn(style.card__image,  {[style['card__image--large']]: hasLargeLogo})} src={logo} />
                <p className={cn(style.card__text, 'text')}>{text}</p>

                <ul className={style.card__tags}>
                    <li>NoA Connect</li>
                    <li>&amp;Co</li>
                    <li>Bold</li>
                    <li>NoA Consultin</li>
                </ul>
            </div>
        </li>
    )
}

export default function Clients(): JSX.Element {
    const clients = [
        {
            name: 'Maersk',
            logo: maersk,
            text: 'Maersk’s overliggende platform - ALL THE WAY - driver både intern og ekstern mobilisering af Maersk’s transformative Global Integrator strategi. Platformen er eksekveret i tæt samarbejde med Maersk og alle NoA bureauer i Danmark ifht. markedsindsigt, Winning Aspiration, design, kommunikation, branded experiences og salgs-support. '
        },
        {
            name: 'Velux',
            logo: velux,
            text: 'Med kampagnen “The Indoor Generation” fra 2018 begyndte VELUX’ rejse fra et funktionelt produkt til et aspirerende brand inden for Home Improvement. Denne rejse fortsætter med uformindsket styrke via nye kampagner og strategisk arbejde ifht. at understøtte og udvikle VELUX’ forretning via kommunikation og media. '
        },
        {
            name: 'SAS',
            logo: sas,
            text: 'SAS Brandposition er eksekveret af NoA gennem design, kommunikation og brand experiences baseret på platformen WE ARE TRAVELERS. Emotionelt drevne eksekveringer har bidraget til at øge og fastholde betalingsviljen med 20% sammenlignet med konkurrenterne.     '
        },
        {
            name: 'Codan',
            logo: codan,
            text: 'For at understøtte position “Forsikring er værd at gøre ordentligt”, har Codan i de seneste år arbejdet sammen med NoA på tværs af de fleste kompetenceområder. Sammen har vi udviklet et komplet CVI og kampagner inden for Codans prioriterede forretningsområde, ligesom vi bistår med selskabets digitale transformation.'
        },
        {
            name: 'TV 2',
            logo: tv2,
            text: 'I samarbejde med TV 2 har NoA udviklet platformen “Alt det vi deler” og den interne medarbejder-lancering. De sidste fire år har vi desuden løbende arbejdet sammen med TV 2 på en lang række forskellige projekter fokuseret på UX og design af digitale services, herunder TV 2 Play.'
        },
        {
            name: 'Danske Bank',
            logo: danskebank,
            text: 'I tæt samarbejde med Danske Banks marketing og kommunikationsafdeling, driver NoA et prioriteret, integreret projekt inden for forretnings-transformation. Alle bureauer er involveret fra strategisk positionering, kulturudvikling og kommunikationsplatform over design og brand experiences til operativ eksekvering i de 4 nordiske markeder.'
        },
        {
            name: 'Telenor',
            logo: telenor,
            text: 'NoA har gennem de sidste 10 år været Telenors marketing og kommunikationspartner. Et samarbejde, der favner strategisk positionering, brand- og kommunikationsplatform, eksekvering gennem hele marketing funnel, butikskampagner, og i stigende grad, datadrevet marketing.'
        },
        {
            name: 'Momondo',
            logo: momondo,
            text: 'Med udgangspunkt i Momondo’s purpose, har NoA hjulpet med at aktivere top funnel. De understøttende reklamekampagner er designet til at øge kunder og øvrige stakeholders emotionelle engagement, og den distinkte position har bidraget til at styrke både virksomhedens værdi og  indtægter.'
        },				
        {
            name: 'CBB',
            logo: cbb,
            hasLargeLogo: true,
            text: 'NoA står bag kampagnen Små Priser, som har spredt glæde blandt danskere og i CBBs forretning de seneste år. Med Små Priser er CBB vokset ved at hente markedsandele i et meget konkurrence-intensivt telemarked.'
        },				
        {
            name: 'Rådet for Sikker Trafik',
            logo: raadet,
            text: 'Som Sikker Trafiks faste kreative partner igennem flere år, har NoA hjulpet med at gøre danskerne bedre til at bruge cykelhjelm. Kampagnerne har, i tillæg til ny og bedre adfærd, udløst priser for effektiv og kreativ kommunikation samt internethits som ”Filips Far”.'
        },				
        {
            name: 'Tivoli',
            logo: tivoli,
            text: 'NoA er Tivoli’s faste partner i forhold til at udvikle og fastholde et distinkt univers. Platformen “Altid som aldrig før” sikrer, at Tivoli’s status i Københavns forlystelsesliv forbliver unik for både lokale københavnere og turister.'
        },								
        {
            name: 'Lakrids by Bülow',
            logo: lakridsbybulow,
            text: 'I tæt samarbejde med Lakrids by Bülow har NoA udviklet den strategiske retning for kundeloyalitet, så kunderne oplever en tydelig forbindelse mellem retail- og e-commerce. Næste step er en global kommunikations-platform.'
        },				
        {
            name: 'Novo Nordisk',
            logo: novonordisk,
            hasLargeLogo: true,
            text: 'Sammen med Danmarks største medicinalvirksomhed, Novo Nordisk, skaber NoA kreative løsninger inden for design, web-applikationer, digitale strategier, markedsundersøgelser, kommunikation og Disease Awareness.'
        }	
    ]
    const [active, setActive] = useState(Math.floor(clients.length / 2))
    const [dragX, setDragX] = useState(0)
    const [isDragging, setIsDragging] = useState(false)
    const xStart = useRef(0)
    const yStart = useRef(0)
    const scroller = useRef<HTMLUListElement>(null)

    useEffect(() => {
        if (scroller.current) {
            const element = scroller.current
            const onTouchMove = (e:TouchEvent) => { 
                const dx = -(xStart.current - e.touches[0].clientX)
                const dy = -(yStart.current - e.touches[0].clientY)
                const threshold = 10
    
                if (Math.abs(dx) > Math.abs(dy) && Math.abs(dx) > threshold) {
                    e.preventDefault()
                    setDragX(dx)
                }
            }

            element.addEventListener('touchmove', onTouchMove, { passive: false })

            return () => {
                element.removeEventListener('touchmove', onTouchMove)  
            }
        }
    }, [])

    return (
        <div

            className={style.clients}
        >
            <Container>
                <h2 className={cn('h', 'h--2', style.clients__title)}>Udvalgte <br />samarbejder</h2>
            </Container>

            <ul
                onTouchStart={e => {
                    xStart.current = e.touches[0].clientX
                    yStart.current = e.touches[0].clientY

                    setIsDragging(true)
                }} 
                onTouchEnd={() => {
                    const threshold = 20

                    if (Math.abs(dragX) > threshold) {
                        if (dragX < 0) {
                            setActive(i => Math.min(i + 1, clients.length - 1))
                        } else {
                            setActive(i => Math.max(i - 1, 0))
                        }
                    }

                    setIsDragging(false)
                    setDragX(0)
                }}
                onTouchCancel={() => {
                    setIsDragging(false)
                    setDragX(0)
                }}
                className={style.clients__cards}
                ref={scroller}
            >
                {clients.map((i, index) => {
                    return (
                        <Card
                            {...i}
                            total={clients.length}
                            active={active}
                            key={i.name}
                            dragX={dragX}
                            isDragging={isDragging}
                            hasLargeLogo={i.hasLargeLogo}
                            index={index}
                            setActive={() => setActive(index)}
                        />
                    )
                })}
            </ul>

            <Container>
                <div className={cn('text', 'text--medium', style.clients__browse)} >
                    <button
                        type="button"
                        disabled={active ===  0}
                        onClick={() => {
                            setActive(i => Math.max(i - 1, 0))
                        }}
                    >
                        Forrige
                    </button>
                    <button 
                        type="button"
                        disabled={active ===  clients.length - 1}
                        onClick={() => {
                            setActive(i => Math.min(i + 1, clients.length - 1))
                        }}
                    >
                        Næste
                    </button>
                </div>

                <p className={cn('text', 'text--medium', style.clients__text)}>
                    NoA er grundlagt i 2014 og arbejder med et væld af nationale og internationale kunder på tværs af brancher.
                </p>

                <p className={cn('h', 'h--1', style.clients__outro)}>
                    Vi skaber vækst via kendskab til dine kunders adfærd.
                </p>
            </Container>
        </div>
    )
}