/* import './assets/style/app.scss' */

import React from 'react'
import ContactUs from '../components/parts/contact-us/ContactUs'
import Intro from '../components/parts/intro/Intro'
import TopVideo from '../components/parts/top-video/TopVideo'
import Clients from '../components/parts/clients/Clients'
import Companies from '../components/parts/companies/Companies'

const Home: React.FC<{ path?: string } > = () => {
    return (
        <>            
            <h1 className="visually-hidden">NoA Ignite Danmark</h1>
            <Intro />
            <TopVideo />
            <Clients />
            <Companies />
            <ContactUs />
        </>
    )
}
export default Home

