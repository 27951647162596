import React from 'react'
import cn from 'classnames'
import style from './Container.module.scss'

export default function Container({
    children,
    className
}: {
    children: React.ReactNode,
    className?: string,
}): JSX.Element {
    return (
        <div className={cn(style.container, className)}>
            {children}
        </div>
    )
}