import React, { ReactNode } from 'react'
import s from './Button.module.scss'

interface ButtonProps {
    children: ReactNode;
    href?: string;
    className?: string;
    onClick?: () => void;
}

export const Button = ({ children, href, onClick, className }: ButtonProps): JSX.Element => {
    const isLink = typeof href !== 'undefined'

    if (isLink) {
        return (
            <a className={`${s.button} ${className}`} target="_blank" rel="noopener noreferrer" href={href}>
                {children}
            </a>
        )
    }



    return (
        <button type="button" className={`${s.button} ${className}`} onClick={onClick}>
            {children}
        </button>
    )
}