export function clamp(value: number, min: number, max: number): number {
    return Math.max(min, Math.min(value, max))
}

type ResponsiveConfig = { [key: number]: string }

export function useResponsiveVideo(config: ResponsiveConfig): [string, number] {
    const last = Object.entries(config) 
    let src: string
    let size: number
    let hit = false

    // this is so weird
    for (const key in config) {
        if (Object.prototype.hasOwnProperty.call(config, key)) { 
            const width = parseInt(key, 10)

            if (window.innerWidth > width / window.devicePixelRatio) {
                src = config[key]
                size = width
                hit = true
            } else {
                src = config[key]
                size = width
                hit = true
                break
            }
        }
    }

    if (!hit) {
        return [last[0][1], parseInt(last[0][0], 10)]
    }

    return [src, size]
}