/* eslint-disable jsx-a11y/media-has-caption */

import React, { useState, useRef, useEffect } from 'react'
import cn from 'classnames'
import style from './TopVideo.module.scss'
import { useResponsiveVideo } from '../../../utils'

export default function TopVideo(): JSX.Element {
    const [loopSrc] = useResponsiveVideo({
        1290: 'https://player.vimeo.com/external/544960567.hd.mp4?s=483989d35c9303dca0c1e718472ca104aa087449&profile_id=175',
        1280: 'https://player.vimeo.com/external/544960567.hd.mp4?s=483989d35c9303dca0c1e718472ca104aa087449&profile_id=174',
        960: 'https://player.vimeo.com/external/544960567.sd.mp4?s=14f7fe6a821f4cb5df9e2f108138baf041099222&profile_id=165',
        640: 'https://player.vimeo.com/external/544960567.sd.mp4?s=14f7fe6a821f4cb5df9e2f108138baf041099222&profile_id=164'
    })
    const [fullSrc] = useResponsiveVideo({
        1290: 'https://player.vimeo.com/external/544960271.hd.mp4?s=577ffc90b67c2bdf30acc7fbcbf50383a6b125fa&profile_id=175',
        1280: 'https://player.vimeo.com/external/544960271.hd.mp4?s=577ffc90b67c2bdf30acc7fbcbf50383a6b125fa&profile_id=174',
        960: 'https://player.vimeo.com/external/544960271.sd.mp4?s=bcd410519418331914cabd0ced7c65c0df04e22f&profile_id=165',
        640: 'https://player.vimeo.com/external/544960271.sd.mp4?s=bcd410519418331914cabd0ced7c65c0df04e22f&profile_id=164'
    })
    const ref = useRef<HTMLVideoElement>(null)
    const ref2 = useRef<HTMLVideoElement>(null)
    const [full, setFull] = useState(false)
    const [muted, setMuted] = useState(true)
    const [height] = useState(window.innerHeight)
    const [playing, setPlaying] = useState(false)

    useEffect(() => {
        const observer = new IntersectionObserver(async ([entry]) => {
            if (entry.isIntersecting) {
                try {
                    await ref.current.play()
                } catch (e) {
                    // do nothing
                }
            } else {
                ref.current.pause()
            }
        }, { threshold: 0, rootMargin: '150px 0px 150px 0px' })

        observer.observe(ref.current)

        return () => {
            observer.disconnect()
        }
    }, [])

    return (
        <div className={style['top-video']}>
            <video
                src={loopSrc}
                ref={ref}
                playsInline
                loop
                preload="none"
                muted={muted}
                poster="/static/images/reelposter.jpg"
                style={{
                    display: full ? 'none' : 'block',
                    height
                }}
                className={style['top-video__video']}
            />
            <video
                src={fullSrc}
                ref={ref2}
                onPause={() => { 
                    setPlaying(false)
                }}
                onPlay={()=> { 
                    setPlaying(true)
                }}
                onPlaying={() => {
                    setFull(true)
                    setPlaying(true)
                }}
                poster="/static/images/reelposter.jpg"
                playsInline={false}
                muted={muted}
                controls={false}
                preload="none" 
                style={{
                    display: full ? 'block' : 'none',
                    height
                }}
                className={style['top-video__video']}
            />

            <button
                className={cn('text--large', style['top-video__play-button'], {
                    [style['top-video__play-button--hidden']]: full
                })}
                lang="en"
                type="button"
                onClick={async () => {
                    try {
                        try {
                            // force fullscreen for android
                            if (window.matchMedia('(max-width: 650px)').matches) {
                                await ref2.current.requestFullscreen()
                            }
                        } catch (e) {
                            // nothing
                        }
                        await ref2.current.play()
                    } catch (e) {
                        //
                    }
                }}
            >
                Play video
            </button>

            <div
                className={cn(style['top-video__controls'], {
                    [style['top-video__controls--hidden']]: !full
                })}
            >
                <button
                    onClick={() => {
                        if (playing) {
                            ref2.current.pause()
                        } else {
                            ref2.current.play()
                        }
                    }}
                    lang="en"
                    type="button"
                >
                    {playing ? 'Pause' : 'Play'}
                </button>
                <button
                    type="button"
                    lang="en"
                    onClick={() => setMuted(i => !i)}
                >
                    {muted ? 'Unmute' : 'Mute'}
                </button>
            </div>
        </div>
    )
}