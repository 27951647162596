import React from 'react'
import { motion } from 'framer-motion'
import style from './Header.module.scss'
import Face from '../face/Face'

export default function Header({ fontsReady }: { fontsReady: boolean }): JSX.Element {
    return (
        <motion.header
            className={style.header}
            initial={{
                y: window.innerHeight / 2 - 100
            }}
            animate={{
                y: fontsReady ? 0 : window.innerHeight / 2 - 100
            }}
            transition={{
                mass: 1,
                type: 'spring',
                bounce: .05,
                delay: .65
            }}

        >
            <Face />
        </motion.header>
    )
}