import React from 'react'
import JobsModule from '../components/parts/jobs/Jobs'
import ContactUs from '../components/parts/contact-us/ContactUs'

const Jobs: React.FC< { path?: string }> = () => {
    return (
        <>    
            <h1 className="visually-hidden">NoA Ignite Danmark Jobs</h1>
            <JobsModule />
            <ContactUs />
        </>
    )
}
export default Jobs
