import React from 'react'
import ContactUs from '../components/parts/contact-us/ContactUs'
import PrivacyPolicyModule from '../components/parts/privacy-policy/PrivacyPolicy'

const PrivacyPolicy: React.FC< { path?: string }> = () => {
    return (
        <>    
            <h1 className="visually-hidden">NoA Ignite Danmark Privacy Policy</h1>
            <PrivacyPolicyModule />
            <ContactUs />
        </>
    )
}
export default PrivacyPolicy
